import Vue from 'vue'
import Store from "@/store";
import VueRouter from 'vue-router'
/* Layouts */
const Layout = () => import('../layouts/Layout')
const Default = () => import('../layouts/BlankLayout')
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')

/* all routes */

const MapPage = () => import("../views/Intimlite/MapPage")

const LoginPage = () => import("../views/Intimlite/LoginPage")
const LoginClientPage = () => import("../views/Intimlite/LoginClientPage")
const LogoutPage = () => import("../views/Intimlite/LogoutPage")
const EditCustomerPage = () => import("../views/Intimlite/EditCustomerPage")
const VideoCallCusPage = () => import("../views/Intimlite/VideoCallCusPage")
const VideoCallCliPage = () => import("../views/Intimlite/VideoCallCliPage")
const VideoCallsListPage = () => import("../views/Intimlite/VideoCallsListPage")
const RechargeCreditsPage = () => import("../views/Intimlite/RechargeCreditsPage")
const UploadPhotosPage = () => import("../views/Intimlite/UploadPhotosPage")
const SignUpUserPage = () => import("../views/Intimlite/SignUpUserPage")
const MyReviewsPage = () => import("../views/Intimlite/MyReviewsPage")
const SignUpCompanionPage = () => import("../views/Intimlite/SignUpCompanionPage")
const SignUpCompanyPage = () => import("../views/Intimlite/SignUpCompanyPage")
const SocialProfile = () => import('../views/SocialMain/Profile/Profile')
const ProfileImage = () => import('../views/SocialMain/Profile/ProfileImage')
const ProfileVideo = () => import('../views/SocialMain/Profile/ProfileVideo')
const ProfileEvent = () => import('../views/SocialMain/Profile/ProfileEvent')
const FriendList = () => import('../views/SocialMain/Friends/FriendList')
const File = () => import('../views/SocialMain/File/File')
const Group = () => import('../views/SocialMain/Group/Group')
const Birthday = () => import('../views/SocialMain/Bithdate/Bithdate')
const Music = () => import('../views/SocialMain/Music/Music')
const Notification = () => import('../views/SocialMain/Notifications/Notification')
const FriendRequest = () => import('../views/SocialMain/Friends/FriendRequest')
/* UIElements View */
const UiAlerts = () => import('../views/core/UiAlerts.vue')
const UiButtons = () => import('../views/core/UiButtons.vue')
const UiBadges = () => import('../views/core/UiBadges.vue')
const UiBreadcrumb = () => import('../views/core/UiBreadcrumb.vue')
const UiColors = () => import('../views/core/UiColors.vue')
const UiTypography = () => import('../views/core/UiTypography.vue')
const UiCards = () => import('../views/core/UiCards.vue')
const UiCarousel = () => import('../views/core/UiCarousel.vue')
const UiEmbedVideo = () => import('../views/core/UiEmbedVideo.vue')
const UiGrid = () => import('../views/core/UiGrid.vue')
const UiModal = () => import('../views/core/UiModal.vue')
const UiListGroup = () => import('../views/core/UiListGroup.vue')
const UiImages = () => import('../views/core/UiImages.vue')
const UiMediaObject = () => import('../views/core/UiMediaObject.vue')
const UiTooltips = () => import('../views/core/UiTooltips.vue')
const UiPopovers = () => import('../views/core/UiPopovers.vue')
const UiNotifications = () => import('../views/core/UiNotifications.vue')
const UiTabs = () => import('../views/core/UiTabs.vue')
const UiPagination = () => import('../views/core/UiPagination.vue')
const UiProgressBars = () => import('../views/core/UiProgressBars.vue')
/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/Default/SignIn1')
const SignUp1 = () => import('../views/AuthPages/Default/SignUp1')
const RecoverPassword1 = () => import('../views/AuthPages/Default/RecoverPassword1')
const LockScreen1 = () => import('../views/AuthPages/Default/LockScreen1')
const ConfirmMail1 = () => import('../views/AuthPages/Default/ConfirmMail1')
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')
const ComingSoon = () => import('../views/Pages/ComingSoon')
const Maintenance = () => import('../views/Pages/Maintenance')
const TimeLine = () => import('../views/Pages/TimeLines')
const Pricing = () => import('../views/Pages/Pricing')
const Pricing1 = () => import('../views/Pages/Pricing1')
const BlankPage = () => import('../views/Pages/BlankPage')
const FAQ = () => import('../views/Pages/FAQ')
const Invoice = () => import('../views/Pages/Invoice')
/* Apps Views */
const EmailListing = () => import('../views/Apps/Email/EmailListing')
const EmailCompose = () => import('../views/Apps/Email/EmailCompose')
const Calendar = () => import('../views/Apps/Calendar/Calendar')
/* Icon Views */
const IconDripicons = () => import('../views/Icons/IconDripicons')
const IconFontawesome5 = () => import('../views/Icons/IconFontawesome5')
const IconLineAwesome = () => import('../views/Icons/IconLineAwesome')
const IconRemixicon = () => import('../views/Icons/IconRemixicon')
const IconUnicons = () => import('../views/Icons/IconUnicons')
/* Tables Views */
const TablesBasic = () => import('../views/Tables/TablesBasic')
const EditableTable = () => import('../views/Tables/EditableTable')
/* Form View */
const FormLayout = () => import('../views/Forms/FormLayout')
const FormValidates = () => import('../views/Forms/FormValidates')
const FormSwitches = () => import('../views/Forms/FormSwitches')
const FormRadios = () => import('../views/Forms/FormRadios')
const FormCheckboxes = () => import('../views/Forms/FormCheckboxes')
/* User View */
const Profile = () => import('../views/User/Profile')
const ProfileEdit = () => import('../views/User/ProfileEdit')
const AddUser = () => import('../views/User/AddUser')
const SocialApp = () => import('../views/Apps/Social/SocialApp')
/* Todo */
const TodoListing = () => import('../views/Apps/Todo/TodoListing')
const ChatIndex = () => import('../views/Apps/Chat/Index')
const AccountSettings = () => import('../views/User/AccountSetting')
Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
    if (!Store.getters["Setting/authUserState"]) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    if (Store.getters["Setting/authUserState"]) {
        next()
        return
    }
    next('/login')
}

const childRoutes = (prop) => [
    {
        path: '/',
        name: prop + '.list',
        meta: {auth: true, name: 'Social App'},
        component: SocialApp,
    }, 
    {
        path: '/profile/:id',
        name: prop + '.profile',
        props: {self: false},
        meta: {auth: true, name: 'Profile'},
        component: SocialProfile
    },
    {
        path: '/my-profile',
        name: prop + '.profile',
        props: {self: true},
        meta: {auth: true, name: 'Profile'},
        component: SocialProfile
    },
    {
        path: 'friend-list',
        name: prop + '.friend-list',
        meta: {auth: true, name: 'Friend List', img: require('../assets/images/page-img/profile-bg3.jpg')},
        component: FriendList
    },
    {
        path: 'group',
        name: prop + '.group',
        meta: {auth: true, name: 'Group', img: require('../assets/images/page-img/profile-bg7.jpg')},
        component: Group
    },
    {
        path: 'profile-image',
        name: prop + '.profile-image',
        meta: {auth: true, name: 'Your Photos', img: require('../assets/images/page-img/profile-bg5.jpg')},
        component: ProfileImage
    },
    {
        path: 'profile-video',
        name: prop + '.profile-video',
        meta: {auth: true, name: 'Profile Video', img: require('../assets/images/page-img/profile-bg9.jpg')},
        component: ProfileVideo
    },
    {
        path: 'profile-event',
        name: prop + '.profile-event',
        meta: {auth: true, name: 'Your Event', img: require('../assets/images/page-img/profile-bg6.jpg')},
        component: ProfileEvent
    },
    {
        path: 'notification',
        name: prop + '.notification',
        meta: {auth: true, name: 'Notification'},
        component: Notification
    },
    {
        path: 'file',
        name: prop + '.file',
        meta: {auth: true, name: 'File'},
        component: File
    },
    {
        path: 'friend-request',
        name: prop + '.friend-request',
        meta: {auth: true, name: 'Friend Request'},
        component: FriendRequest
    },
    {
        path: 'birthday',
        name: prop + '.birthday',
        meta: {auth: true, name: 'Birthday', img: require('../assets/images/page-img/profile-bg4.jpg')},
        component: Birthday
    },

    {
        path: '/loginpage',
        name: prop + '.loginpage',
        meta: {auth: true, name: 'LoginPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: LoginPage
    },
    {
        path: '/loginclientpage',
        name: prop + '.loginclientpage',
        meta: {auth: true, name: 'LoginClientPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: LoginClientPage
    },

    {
        path: '/editcustomer',
        name: prop + '.editcustomer',
        meta: {auth: true, name: 'EditCustomerPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: EditCustomerPage
    },
    {
        path: '/videocallcus/:callId/:cliName',
        name: prop + '.videocallcus',
        meta: {auth: true, name: 'VideoCallCusPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: VideoCallCusPage
    },
    {
        path: '/videocallcli/:callId/:cusName',
        name: prop + '.videocallcli',
        meta: {auth: true, name: 'VideoCallCliPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: VideoCallCliPage
    },
    {
        path: '/videocallslist',
        name: prop + '.videocallslist',
        meta: {auth: true, name: 'VideoCallsListPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: VideoCallsListPage
    },
    {
        path: '/rechargecredits',
        name: prop + '.rechargecredits',
        meta: {auth: true, name: 'RechargeCreditsPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: RechargeCreditsPage
    },
    {
        path: '/uploadphotos',
        name: prop + '.uploadphotos',
        meta: {auth: true, name: 'UploadPhotosPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: UploadPhotosPage
    },

    {
        path: '/myreviews',
        name: prop + '.myreviews',
        meta: {auth: true, name: 'MyReviewsPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: MyReviewsPage
    },
    {
        path: '/map',
        name: prop + '.map',
        meta: {auth: true, name: 'MapPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: MapPage
    },
    {
        path: '/logoutpage',
        name: prop + '.logoutpage',
        meta: {auth: true, name: 'LogoutPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: LogoutPage
    },
    {
        path: '/registration-user',
        name: prop + '.signupuserpage',
        meta: {auth: true, name: 'SignUpUserPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: SignUpUserPage
    },
    {
        path: '/registration-companion',
        name: prop + '.signupcompanionpage',
        meta: {auth: true, name: 'SignUpCompanionPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: SignUpCompanionPage
    },
    {
        path: '/registration-company',
        name: prop + '.signupcompanypage',
        meta: {auth: true, name: 'SignUpCompanyPage', img: require('../assets/images/page-img/profile-bg2.jpg')},
        component: SignUpCompanyPage
    },
    {
        path: 'music',
        name: prop + '.music',
        meta: {auth: true, name: 'Music', img: require('../assets/images/page-img/profile-bg8.jpg')},
        component: Music
    },
    {
        path: '/account-setting',
        name: 'accountSetting',
        meta: {auth: true, name: 'AccountSettings'},
        component: AccountSettings
    },{
        path: 'email',
        name: prop + '.email',
        meta: {auth: true, name: 'Email'},
        component: EmailListing
    },
    {
        path: 'email-compose',
        name: prop + '.email.compose',
        meta: {auth: true, name: 'New Email'},
        component: EmailCompose
    },
    {
        path: 'chat',
        name: prop + '.chat',
        meta: {auth: true, name: 'Chat'},
        component: ChatIndex
    },
]

const coreChildRoute = (prop) => [
    {
        path: 'ui-colors',
        name: prop + '.color',
        meta: {auth: true, name: 'Colors'},
        component: UiColors
    },
    {
        path: 'ui-typography',
        name: prop + '.typography',
        meta: {auth: true, name: 'Typography'},
        component: UiTypography
    },
    {
        path: 'ui-alerts',
        name: prop + '.alert',
        meta: {auth: true, name: 'Alert'},
        component: UiAlerts
    },
    {
        path: 'ui-buttons',
        name: prop + '.button',
        meta: {auth: true, name: 'Button'},
        component: UiButtons
    },
    {
        path: 'ui-breadcrumb',
        name: prop + '.breadcrumb',
        meta: {auth: true, name: 'Breadcrumb'},
        component: UiBreadcrumb
    },
    {
        path: 'ui-badges',
        name: prop + '.badges',
        meta: {auth: true, name: 'Badges'},
        component: UiBadges
    },
    {
        path: 'ui-cards',
        name: prop + '.cards',
        meta: {auth: true, name: 'Card'},
        component: UiCards
    },
    {
        path: 'ui-carousel',
        name: prop + '.carousel',
        meta: {auth: true, name: 'Carousel'},
        component: UiCarousel
    },
    {
        path: 'ui-grid',
        name: prop + '.grid',
        meta: {auth: true, name: 'Grid'},
        component: UiGrid
    },
    {
        path: 'ui-embed-video',
        name: prop + '.embed-video',
        meta: {auth: true, name: 'Embed Video'},
        component: UiEmbedVideo
    },
    {
        path: 'ui-modal',
        name: prop + '.modal',
        meta: {auth: true, name: 'Model'},
        component: UiModal
    },
    {
        path: 'ui-listgroup',
        name: prop + '.listgroup',
        meta: {auth: true, name: 'List Group'},
        component: UiListGroup
    },
    {
        path: 'ui-images',
        name: prop + '.images',
        meta: {auth: true, name: 'Image'},
        component: UiImages
    },
    {
        path: 'ui-media-object',
        name: prop + '.media-object',
        meta: {auth: true, name: 'Media Object'},
        component: UiMediaObject
    },
    {
        path: 'ui-pagination',
        name: prop + '.pagination',
        meta: {auth: true, name: 'Paginations'},
        component: UiPagination
    },
    {
        path: 'ui-progressbars',
        name: prop + '.progressbars',
        meta: {auth: true, name: 'Progressbar'},
        component: UiProgressBars
    },
    {
        path: 'ui-tooltips',
        name: prop + '.tooltips',
        meta: {auth: true, name: 'Tooltip'},
        component: UiTooltips
    },
    {
        path: 'ui-popovers',
        name: prop + '.popovers',
        meta: {auth: true, name: 'Popover'},
        component: UiPopovers
    },
    {
        path: 'ui-notifications',
        name: prop + '.notifications',
        meta: {auth: true, name: 'Notification'},
        component: UiNotifications
    },
    {
        path: 'ui-tabs',
        name: prop + '.tabs',
        meta: {auth: true, name: 'Tabs'},
        component: UiTabs
    }
]
const formChildRoute = (prop = false) => [
    {
        path: 'form-layout',
        name: prop + '.layout',
        meta: {auth: true, name: 'Layout'},
        component: FormLayout
    },
    {
        path: 'form-validate',
        name: prop + '.validate',
        meta: {auth: true, name: 'Validate'},
        component: FormValidates
    },
    {
        path: 'form-switches',
        name: prop + '.switch',
        meta: {auth: true, name: 'Switch'},
        component: FormSwitches
    },
    {
        path: 'form-radios',
        name: prop + '.radio',
        meta: {auth: true, name: 'Radio'},
        component: FormRadios
    },
    {
        path: 'form-checkboxes',
        name: prop + '.checkbox',
        meta: {auth: true, name: 'Checkbox'},
        component: FormCheckboxes
    }
]

const tableChildRoute = (prop = false) => [
    {
        path: 'tables-basic',
        name: prop + '.basic',
        meta: {auth: true, name: 'Basic'},
        component: TablesBasic
    },
    {
        path: 'editable',
        name: prop + '.editable',
        meta: {auth: true, name: 'Editable'},
        component: EditableTable
    }
]

const iconChildRoute = (prop = false) => [
    {
        path: 'dripicons',
        name: prop + '.dripicons',
        meta: {auth: true, name: 'Dripicons'},
        component: IconDripicons
    },
    {
        path: 'fontawesome-5',
        name: prop + '.fontawesome-5',
        meta: {auth: true, name: 'Font Awsome'},
        component: IconFontawesome5
    },
    {
        path: 'lineawesome',
        name: prop + '.lineawesome',
        meta: {auth: true, name: 'Line Awsome'},
        component: IconLineAwesome
    },
    {
        path: 'remixicon',
        name: prop + '.remixicon',
        meta: {auth: true, name: 'Remixicon'},
        component: IconRemixicon
    },
    {
        path: 'unicons',
        name: prop + '.unicons',
        meta: {auth: true, name: 'Unicon'},
        component: IconUnicons
    }
]

const appChildRoute = (prop = false) => [
    {
        path: 'calendar',
        name: prop + '.calendar',
        meta: {auth: true, name: 'Calendar', img: require('../assets/images/page-img/profile-bg6.jpg')},
        component: Calendar
    },
    {
        path: 'chat',
        name: prop + '.chat',
        meta: {auth: true, name: 'Chat'},
        component: ChatIndex
    },
    {
        path: 'todo',
        name: prop + '.todo',
        meta: {auth: true, name: 'Todo'},
        component: TodoListing
    },
    
]

const authChildRoutes = (prop = false) => [
    {
        path: 'sign-in1',
        name: prop + '.sign-in1',
        meta: {auth: true},
        component: SignIn1
    },
    {
        path: 'sign-up1',
        name: prop + '.sign-up1',
        meta: {auth: true},
        component: SignUp1
    },
    {
        path: 'password-reset1',
        name: prop + '.password-reset1',
        meta: {auth: true},
        component: RecoverPassword1
    },
    {
        path: 'lock-screen1',
        name: prop + '.lock-screen1',
        meta: {auth: true},
        component: LockScreen1
    },
    {
        path: 'confirm-mail1',
        name: prop + '.confirm-mail1',
        meta: {auth: true},
        component: ConfirmMail1
    }
]
const defaultlayout = (prop = false) => [
    {
        path: 'timeline',
        name: prop + '.timeline',
        meta: {auth: true, name: 'Timeline'},
        component: TimeLine
    },
    {
        path: 'invoice',
        name: prop + '.invoice',
        meta: {auth: true, name: 'Invoice'},
        component: Invoice
    },
    {
        path: 'blank-page',
        name: prop + '.blank-page',
        meta: {auth: true, name: 'Blank Page'},
        component: BlankPage
    },
    {
        path: 'pricing',
        name: prop + '.pricing',
        meta: {auth: true, name: 'Pricing'},
        component: Pricing
    },
    {
        path: 'pricing-1',
        name: prop + '.pricing1',
        meta: {auth: true, name: 'Pricing 1'},
        component: Pricing1
    },
    {
        path: 'faq',
        name: prop + '.faq',
        meta: {auth: true, name: 'Faq'},
        component: FAQ
    }
]

const pagesChildRoutes = (prop = false) => [
    {
        path: 'error/:code',
        name: prop + '.error',
        meta: {auth: true},
        component: ErrorPage
    },
    {
        path: 'coming-soon',
        name: prop + '.coming-soon',
        meta: {auth: true},
        component: ComingSoon
    },
    {
        path: 'maintenance',
        name: prop + '.maintenance',
        meta: {auth: true},
        component: Maintenance
    }
]
const userChildRoute = (prop = false) => [
    {
        path: '/profile_old/:id',
        name: prop + '.profile',
        meta: {auth: true, name: 'Profile'},
        component: Profile
    },
    {
        path: 'profile-edit',
        name: prop + '.edit',
        meta: {auth: true, name: 'Edit Profile'},
        component: ProfileEdit
    },
    {
        path: 'add-user',
        name: prop + '.add',
        meta: {auth: true, name: 'Add Profile'},
        component: AddUser
    }
]
const routes = [
    {
        path: '/',
        name: 'social',
        component: Layout,
        children: childRoutes('social'
        ),

    },
    {
        path: '/core',
        name: 'core',
        component: Layout,
        meta: {auth: true},
        children: coreChildRoute('core'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/table',
        name: 'table',
        component: Layout,
        meta: {auth: true},
        children: tableChildRoute('table')
    },
    {
        path: '/icon',
        name: 'icon',
        component: Layout,
        meta: {auth: true},
        children: iconChildRoute('icon')
    },
    {
        path: '/login',
        name: 'auth1',
        component: AuthLayout,
        meta: {auth: true},
        children: authChildRoutes('auth1'),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/pages',
        name: 'pages',
        component: Default,
        meta: {auth: true},
        children: pagesChildRoutes('default')
    },
    {
        path: '/extra-pages',
        name: 'extra-pages',
        component: Layout,
        meta: {auth: true},
        children: defaultlayout('extra-pages')
    },
    {
        path: '/app',
        name: 'app',
        component: Layout,
        meta: {auth: true},
        children: appChildRoute('app')
    },
    {
        path: '/user',
        name: 'user',
        component: Layout,
        meta: {auth: true},
        children: userChildRoute('user')
    },
    {
        path: '/form',
        name: 'form',
        component: Layout,
        meta: {auth: true},
        children: formChildRoute('form')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
})

export default router
