export default {

    activePageCommit(state, data) {
        state.activePage = data
    },
    setLangCommit(state, data) {
        state.lang = data
    },
    layoutModeCommit(state, data) {
        state.layoutMode = data
    },
    userLogged(state, data) {
        state.authUser = data
    },
    customerData(state, data) {
        state.customer = data
    },
    clientData(state, data) {
        state.client = data
    },
    logout(state) {
        state.token = null
        state.authUser = false
    },
    authUserAction(state, payload) {
        let data = payload

        if (data.token) {
            state.token = data.token
            state.authUser = true;
        } else {
            state.authUser = false;
        }

    }
}
