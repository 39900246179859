import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import Vuex from 'vuex'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueFlashMessage from 'vue-flash-message';
import VueMeta from 'vue-meta'
import vmodal from 'vue-js-modal'

require('vue-flash-message/dist/vue-flash-message.min.css');
global.Raphael = Raphael

Vue.config.productionTip = false

// URL SETUP Vue.prototype.$mediaBaseUrl = 'https://intimclub.fra1.digitaloceanspaces.com'
Vue.prototype.$mediaBaseUrl = 'https://api.intimclub.fun'

let vm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
Vue.use(VueAxios, axios)
Vue.use(VueFlashMessage, {
    messageOptions: {
        timeout: 3000
    }
});

Vue.use(Vuex)
Vue.use(vmodal, {dialog: true})
Vue.use(VueFlashMessage, {method: 'iPreferQuickSilver'});
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDAIBuTBt8bPTxn4AIs77CsgqyvpSsb7C4',
        map_ids: "9693bb880deea154",
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },


})

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

window.vm = vm
