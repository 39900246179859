<template>
  <router-view/>
</template>
<script>
import {socialvue} from './config/pluginInit'
import router from '@/router'

export default {
  name: 'App',
  components: {},
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    }
  },
  data() {
    return {
      logged: false
    }
  },
  watch: {
    logged: function (val) {
      if (val) {
        router.push({path: '/'})
      }
    }
  },
  mounted() {
    socialvue.mainIndex()
  }
}


</script>
<style lang="scss">
@import "assets/scss/style.scss";

.ri-arrow-right-line, .ri-arrow-left-line {
  color: #ef476f !important;
}
</style>
