import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Setting from './Setting/index'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        Setting
    },
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    strict: debug
})
