export default {
    token: state => state.token,
    customerData: state => state.customer,
    clientData: state => state.client,
    authUserState: state => state.authUser,
    activePage: state => state.activePage,
    langState: state => state.lang,
    langOptionState: state => state.langOption,
    darkModeState: state => state.layoutMode.dark,
    rtlModeState: state => state.layoutMode.rtl
}
